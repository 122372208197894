@font-face {
	font-family: 'Matter Regular';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('https://r.v-office.com/fonts/7009Matter-Regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('https://r.v-office.com/fonts/7009Matter-Regular.woff2.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}


@font-face {
	font-family: 'Matter Medium';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('https://r.v-office.com/fonts/2004Matter-Medium.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('https://r.v-office.com/fonts/2004Matter-Medium.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Northwell';
	src: url('RESOURCE/fonts/Northwell.eot');
	src: url('RESOURCE/fonts/Northwell.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Northwell.woff2') format('woff2'),
		url('RESOURCE/fonts/Northwell.woff') format('woff'),
		url('RESOURCE/fonts/Northwell.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Barlow';
	src: url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff2') format('woff2'),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Barlow';
	src: url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff2') format('woff2'),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}