.ferienanlagen-v1 {
	margin-top: var(--page-margin-top);
	padding-top: var(--page-padding-top);

	.facilities-col {
		padding-bottom: 30px;

		.facility {
			margin-bottom: 30px;
			height: 100%
		}
	}



	.facility-card {
		background-color: #fff;
		border: 1px solid var(--color-grey-normal);
		transition: all 0.2s;
		height: 100%;
		border-radius: var(--border-radius);



		&:hover {
			@media(min-width:992px) {
				box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
				transform: scale(1.015);

			}
		}

		.facility-container {
			height: 100%;
			position: relative;
			padding-bottom: 60px;

			.img-col {
				position: relative;
				width: 100%;

				img {
					border-top-left-radius: var(--border-radius);
					border-top-right-radius: var(--border-radius);
				}
			}

			.facility-facts {
				padding: 0px 10px;

				.facility-title {
					padding-top: 10px;
				}

				.place {
					font-size: var(--font-size-md);

					.fa {
						color: var(--color-grey-dark);
					}
				}

				.button-row {
					padding: 20px 0px;
					position: absolute;
					bottom: 15px;
					right: 16px;
					padding: 0;
				}

			}

		}
	}
}