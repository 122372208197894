.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.alert.alert-info {
		margin-bottom: 0;
	}

	li {
		margin-left: 20px;
	}
}